// テキスト編集ツール

<template lang="pug">
.text-tool.md__rounded-lg.p-3

  .sm__flex

    .left.flex-grow

      //- 文字列
      .text
        input.input-text.w-full.text-lg(
          type='text',
          placeholder='文字を入力...',
          v-model.trim='text'
        )

      //- 色
      .color.mt-2.flex.items-center
        p.w-10 色
        ul.flex-grow.clearfix
          li.w-8.h-8.float-left.rounded-full.border-4.ml-1.first__ml-0.cursor-pointer(
            v-for='item in colors',
            :style='{ backgroundColor: item }',
            :class='{ "border-red-300": item === selectedColor }',
            @click='selectedColor = item'
          )

      //- 書体
      .font.mt-2.flex.items-center
        p.w-10 書体
        div.flex-grow
          select.select.block.w-full(
            v-model='selectedFont'
          )
            option(
              v-for='font in fonts'
              :value='font.code'
            ) {{ font.name }}

    //- ボタン群
    .right.text-center.mt-2.sm__mt-0.sm__ml-3
      button.btn.bg-orange-500.text-white.w-32.sm__block(
        @click='clickOk'
      ) OK
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import colors from '../../../utils/colors'

export default {
  name: 'TextTool',

  computed: {
    ...mapGetters({
      fonts: 'editor/availableFonts',
      selectedContent: 'editor/selectedContent'
    }),
    colors () { return colors.text },
    text: {
      get () { return this.selectedContent.value || '' },
      set (value) {
        this.updateContent({
          contentId: this.selectedContent.id,
          params: {
            value,
            width: 8 * value.length
          }
        })
      }
    },
    selectedColor: {
      get () { return this.selectedContent.fill },
      set (value) {
        this.updateContent({
          contentId: this.selectedContent.id,
          params: {
            fill: value
          }
        })
      }
    },
    selectedFont: {
      get () { return this.selectedContent.font },
      set (value) {
        this.updateContent({
          contentId: this.selectedContent.id,
          params: {
            font: value
          }
        })
      }
    }
  },

  methods: {
    ...mapActions({
      updateContent: 'editor/updateContent'
    }),
    clickOk () {
      this.$emit('clickOk')
    }
  }
}
</script>

<style lang="sass" scoped>
.text-tool
  background: rgba(255,255,255,.9)
</style>
