// アップロード用のURLの生成

import api from '../api'

const path = '/books/{id}/upload-url'

export default ({ bookId, filename }) => {
  return api({
    url: path.replace('{id}', bookId),
    auth: true,
    method: 'post',
    params: { filename },
    // サイレントとして動かす
    silent: true
  })
}
