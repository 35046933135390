// アップロード写真の取得

import api from '../api'

const path = '/books/{id}/uploads'

export default bookId => {
  return api({
    url: path.replace('{id}', bookId),
    auth: true
  })
}
