// 編集画面

<template lang="pug">
//- height:100%でなくabsoluteにするのはsafariのバグ対策のため
.editor-main.absolute.inset-0.flex.flex-col

  //- main.flex-auto.h-full.relative
  spread-viewer-wrapper(
    @dragContentStart='dragContent = true',
    @dragContentEnd='dragContent = false'
  )

  //- 写真編集ツール
  //- ドラッグ中は透明度を高くして、重なっていても位置が把握できるようにする
  .tools.fixed.bottom-0.md__px-6.md__pb-8.z-50.w-full.pointer-events-none(
    v-if='showTool',
    :class='{ "opacity-25": dragContent }'
  )
    photo-tool.pointer-events-auto.max-w-4xl.mx-auto(
      v-if='selectedContentIsValidPhoto'
      @clickChangePhoto='showPhotoSelectorModal',
      @clickOk='unselectContent'
    )
    text-tool.pointer-events-auto.max-w-3xl.mx-auto(
      v-else-if='selectedContentIsText'
      @clickOk='unselectContent'
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import HeaderMenu from './components/header-menu'
import SpreadViewerWrapper from './components/spread-viewer-wrapper'
import PhotoTool from './components/photo-tool'
import TextTool from './components/text-tool'

// モーダル
import IntroductionForEditorModal from '../../modals/introduction-for-editor'
import PhotoSelectorModal from '../../modals/photo-selector'
import TutorialForEditor from '../../modals/tutorial-for-editor'

import SideMenu from '../../components/side-menu'

const sideMenuItems = [
  { title: 'マイページトップ', code: 'myhome' },
  { title: '使い方の説明', code: 'tutorial' },
  { title: '見開き表示/1ページ表示切替', code: 'viewmode' },
  { title: 'クラス名等再設定', code: 'reset' }
]

export default {
  name: 'EditorMain',

  components: {
    HeaderMenu,
    SpreadViewerWrapper,
    PhotoTool,
    TextTool,
    SideMenu
  },

  data () {
    return {
      // 定期的に保存するためのsetIntervalのid
      intervalIdAutoSaving: null,
      // コンテントをドラッグ中か
      dragContent: false
    }
  },

  computed: {
    ...mapGetters({
      bookId: 'editor/bookId',
      selectedContentId: 'editor/selectedContentId',
      selectedContent: 'editor/selectedContent',
      showEditorIntroModal: 'editor/showEditorIntroModal'
    }),
    // ツールボックスを表示するかどうか
    showTool () {
      return this.selectedContentIsValidPhoto || this.selectedContentIsText
    },
    // 選択している要素が有効(url値をもっている)な写真要素か
    selectedContentIsValidPhoto () {
      return this.selectedContent && this.selectedContent.type === 'photo' && this.selectedContent.url
    },
    // 選択している要素がテキスト要素か
    selectedContentIsText () {
      return this.selectedContent && this.selectedContent.type === 'text'
    },
    sideMenuItems () {
      return sideMenuItems
    }
  },

  async mounted () {
    // 初回モーダル表示
    if (this.showEditorIntroModal) {
      // 次回モーダルを出さないように
      this.setShowEditorIntroModal(false)
      const result = await this.$showModal(IntroductionForEditorModal, true)
      // result.ok = trueの場合チュートリアル表示とみなす
      if (result.ok) {
        this.showTutorialModal()
      }
    }
    // 自動保存
    const intervalMs = parseInt(process.env.VUE_APP_AUTO_SAVE_INTERVAL, 10) || 0
    if (intervalMs) {
      this.intervalIdAutoSaving = setInterval(() => {
        // サイレントモードで保存。また例外が出ても無視
        try {
          this._saveBook(true)
        } catch (e) {
        }
      }, intervalMs)
    }
  },

  beforeDestroy () {
    // setIntervalの解除
    clearInterval(this.intervalIdAutoSaving)
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
      updateContent: 'editor/updateContent',
      unselectContent: 'editor/unselectContent',
      selectContent: 'editor/selectContent',
      _saveBook: 'editor/saveBook',
      pushHistory: 'editor/pushHistory',
      setShowEditorIntroModal: 'editor/setShowEditorIntroModal'
    }),

    // チュートリアルモーダルの表示
    showTutorialModal () {
      this.$showModal(
        TutorialForEditor,
        true
      )
    },

    // 写真選択モーダルの表示
    async showPhotoSelectorModal () {
      const result = await this.$showModal(
        PhotoSelectorModal,
        true
      )
      // キャンセル時はセレクト状態も解除して終了
      if (!result.ok) {
        this.unselectContent()
        return
      }
      // 現在選択中の写真要素をアップデート
      this.updateContent({
        contentId: this.selectedContent.id,
        params: {
          ...result.obj,
          photoX: 0,
          photoY: 0,
          photoScale: 1.05,
          photoRotate: 0
        }
      })
    }
  },

  watch: {
    // 選択中のコンテントが更新されたかどうか。selectedContentで判定すると、
    // 選択中の位置移動時も、コンテントのオブジェクト自体は更新されるので、
    // その都度呼ばれてしまうため、selectedContentIdをウォッチする
    selectedContentId (contentId) {
      if (!contentId) return
      // ヒストリにプッシュ
      this.pushHistory()
      if (this.selectedContent.type === 'photo' && !this.selectedContent.url) {
        this.showPhotoSelectorModal()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.editor-main
  .add
    bottom: 1rem
    left: 50%
    margin-left: -2rem
</style>
