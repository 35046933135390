// 特定のファイルのステータスを取得

import api from '../api'

const path = '/books/{id}/upload-status?name={filename}'

export default ({ bookId, filename }) => {
  return api({
    // '+' が入っていると正常に処理できないので、URLエンコードする
    url: path.replace('{id}', bookId).replace('{filename}', encodeURIComponent(filename)),
    auth: true,
    silent: true
  })
}
