// 写真を回転したり拡大縮小したりするためのツール

<template lang="pug">
.photo-tool.md__rounded-lg.p-3

  //- 拡大縮小
  .scale.flex.items-center
    div.w-24 拡大縮小
    div
      fa-icon.text-xl(icon='minus')
    div.flex-1.px-2
      vue-slider(
        :dot-size='30',
        v-model='selectedPhotoScale', :min='1.0', :max='2.0', :interval='0.001',
        ref='slider', :contained='true', :lazy='true', tooltip='none'
      )
    div
      fa-icon.text-xl(icon='plus')

  //- 回転
  .rotate.flex.items-center
    div.w-24 回転
    div
      fa-icon.text-xl(icon='undo')
    div.flex-1.px-2
      vue-slider(
        :dot-size='30',
        v-model='selectedPhotoRotate', :min='-180', :max='180', :interval='2', tooltip='none'
      )
    div
      fa-icon.text-xl(icon='undo', flip='horizontal')

  //- ボタン群
  .buttons.text-center.md__text-right.mt-3
    .btn.bg-green-600.text-white.px-5(
      @click='clickChangePhoto'
    ) 写真変更
    .btn.bg-orange-500.text-white.px-5.ml-3(
      @click='clickOk'
    ) OK
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import get from 'lodash.get'

export default {
  name: 'PhotoTool',

  components: {
    VueSlider
  },

  computed: {
    ...mapGetters({
      selectedContent: 'editor/selectedContent'
    }),
    selectedPhotoScale: {
      // this.selectedContent が更新されても、全く同一の値だと
      // vue-slider-component の内部の値が更新されないので、
      /// 微小なランダム値を加えて、値が変わるように
      get () {
        const randomValue = Math.random() / 10000
        return get(this.selectedContent, 'photoScale', 1.0) + randomValue
      },
      set (value) {
        if (!this.selectedContent) return
        this.updateContent({
          contentId: this.selectedContent.id,
          params: { photoScale: value }
        })
      }
    },
    selectedPhotoRotate: {
      get () { return get(this.selectedContent, 'photoRotate', 0) },
      set (value) {
        if (!this.selectedContent) return
        this.updateContent({
          contentId: this.selectedContent.id,
          params: { photoRotate: value }
        })
      }
    }
  },

  methods: {
    ...mapActions({
      updateContent: 'editor/updateContent'
    }),
    // 写真変更ボタンが押された
    clickChangePhoto () {
      this.$emit('clickChangePhoto')
    },
    // OKボタンが押された
    clickOk () {
      this.$emit('clickOk')
    }
  }
}
</script>

<style lang="sass" scoped>
.photo-tool
  background: rgba(255,255,255,.9)
</style>
