// 編集画面に最初に来た際に表示するダイアログ

<template lang="pug">
modal-base.introduction-for-editor(
  size='s',
  @clickClose='$closeModal(false)'
)

  .p-3

    h1.text-gray-700.font-bold.text-xl.text-center 編集画面へようこそ!

    .text-center.my-5.text-blue-600
      transition(appear)
          fa-icon.icon(icon='edit')

    p.text-gray-700
      | 編集画面では、写真を変更したり、スタンプや文字を追加したりすることが可能です。

  template(v-slot:footer)
    .buttons.mt-3.p-3.text-center
      button.btn.bg-blue-500.text-white(
        @click='$closeModal(true)'
      ) 使い方の説明を見る
      br
      button.btn.mt-5(
        @click='$closeModal(false)'
      ) 編集開始
</template>

<script>
import ModalBase from './modal-base'

export default {
  name: 'IntroductionForEditor',

  components: {
    ModalBase
  }
}
</script>

<style lang="sass" scoped>
.introduction-for-editor
  .icon
    font-size: 5rem
.v-enter-active
  transition: all 1.5s ease
.v-enter
  transform: scale(1.2) rotateY(180deg)
  opacity: 0
</style>
