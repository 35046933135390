// 写真選択ダイアログ

<template lang="pug">
modal-base.photo-selector(
  title='写真の選択',
  size='l',
  ref='modalBase',
  @clickClose='$closeModal(false)'
)

  template(v-slot:header)
    //- タブ
    ul.tabs.flex.bg-gray-500.pt-3.border-b-2.border-white
      li.flex-1.px-1
        .p-2.rounded-t.bg-gray-400.text-center.cursor-pointer.select-none(
          :class='{ "selected": type === "purchased" }',
          @click='type = "purchased"'
        ) 購入済み写真
      li.flex-1.px-1
        .p-2.rounded-t.bg-gray-400.text-center.cursor-pointer.select-none(
          :class='{ "selected": type === "free" }',
          @click='type = "free"'
        ) フリー写真
      li.flex-1.px-1
        .p-2.rounded-t.bg-gray-400.text-center.cursor-pointer.select-none(
          :class='{ "selected": type === "uploaded" }',
          @click='type = "uploaded"'
        ) アップロード

  div.overflow-x-hidden(
    @dragover.prevent='fileDragging = true',
    @dragleave.prevent='fileDragging = false',
    @drop.prevent='dropFile'
  )
    .uploader.p-1(v-if='type === "uploaded"')
      label.block.bg-yellow-200.border-2.border-dashed.border-gray-400.p-3.text-center.text-blue-800.select-none.cursor-pointer(
        :class='{ "bg-green-200": fileDragging }'
      )
        fa-icon(icon='cloud-upload-alt')
        | ここに写真をドロップ
        br
        | または
        span.underline こちら
        | を押してアップロード
        .text-sm (最大50枚までアップロードできます)
        input.hidden(
          type='file', accept='image/jpeg',
          @change='changeFile'
        )

    ul.photos.clearfix.border-2.border-white
      li.float-left.w-1_3.sm__w-1_4.lg__w-1_5.border-2.border-white.bg-gray-300.select-none.cursor-pointer.relative(
        v-for='photo in photos',
        @click='clickPhoto(photo)'
      )
        .photo.relative
          .bg-fit.absolute.inset-0(
            :style='`background-image: url("${photo.url.small}")`'
          )
        .placed.absolute.top-0.left-0.text-center.bg-green-700.text-white.p-1.leading-none(
          v-if='isPlaced(photo)'
        )
          fa-icon(icon='check')
          .text-xs.font-bold 使用中
        //- 使用中の写真のついては削除ボタンは付けない
        .remove-button.absolute.bg-red-500.top-0.right-0.z-10.items-center.justify-center.text-white.px-3.py-1(
          v-else-if='type === "uploaded"',
          @click.stop='removeUploadPhoto(photo)'
        )
          fa-icon(icon='times')
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'

import ModalBase from './modal-base'
import generateUploadUrlApi from '../api/books/generate-upload-url'
import getUploadStatusApi from '../api/books/get-upload-status'
import uploadFileApi from '../api/upload-file'
import getUploadPhotosApi from '../api/books/get-upload-photos'
import removeUploadPhotoApi from '../api/uploads/remove'
import errorCodes from '../errors/codes'
import sleep from '../utils/sleep'

export default {
  name: 'PhotoSelector',

  components: {
    ModalBase
  },

  data () {
    return {
      type: 'purchased',
      // ファイルをドラッグ中か
      fileDragging: false,
      // アップロード中か
      uploading: false,
      // アップロード済み写真
      uploadPhotos: []
    }
  },

  computed: {
    ...mapGetters({
      purchasedPhotos: 'editor/purchasedPhotos',
      freePhotos: 'editor/freePhotos',
      bookId: 'editor/bookId',
      // 配置済み写真のチェックサム
      placedPhotosChecksums: 'editor/placedPhotosChecksums'
    }),
    photos () {
      if (this.type === 'purchased') return this.purchasedPhotos || []
      else if (this.type === 'free') return this.freePhotos || []
      else return this.uploadPhotos
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      getPurchasedPhotos: 'editor/getPurchasedPhotos',
      getFreePhotos: 'editor/getFreePhotos',
      setError: 'app/setError',
      incrementLoadings: 'app/incrementLoadings',
      decrementLoadings: 'app/decrementLoadings'
    }),
    async init () {
      try {
        if (this.type === 'purchased') await this.getPurchasedPhotos()
        else if (this.type === 'free') await this.getFreePhotos()
        else await this.getUploadPhotos()
        // スクロール位置を先頭に戻す
        if (this.$refs.modalBase) this.$refs.modalBase.scrollMainToTop()
      } catch (e) {
        this.setError(e)
      }
    },
    // 写真がクリックされた
    clickPhoto (photo) {
      this.$closeModal(true, {
        dataWidth: photo.dataWidth,
        dataHeight: photo.dataHeight,
        dataChecksum: photo.dataChecksum,
        url: photo.url.large
      })
    },
    // 対象の写真が配置済みか
    isPlaced (photo) {
      return this.placedPhotosChecksums.indexOf(photo.dataChecksum) >= 0
    },
    // ファイルがドロップされた
    dropFile (e) {
      this.fileDragging = false
      // アップロードタブ選択中でない、または、アップロード処理中なら終了
      if (this.type !== 'uploaded' || this.uploading) return
      const files = get(e, 'dataTransfer.files', [])
      this.uploadFilesAsync(files)
    },
    // ファイルが選択された
    changeFile (e) {
      if (e.target.files.length > 0) {
        this.uploadFilesAsync(e.target.files)
      }
    },
    // アップロード済み写真の一覧の取得
    async getUploadPhotos () {
      const response = await getUploadPhotosApi(this.bookId)
      if (!response.ok) {
        this.setError(errorCodes.GET_UPLOAD_PHOTOS_ERROR)
        return
      }
      this.uploadPhotos = response.payload.items
    },
    // ファイルのアップロード
    async uploadFilesAsync (files) {
      try {
        this.incrementLoadings()

        this.uploading = true
        for (const file of files) {
          // アップロード先URLの生成
          const response = await generateUploadUrlApi({
            bookId: this.bookId,
            filename: file.name
          })
          if (!response.ok) {
            const code = get(response, 'payload.code')
            if (code === 'REACH_MAX_UPLOAD_FILE_NUM') {
              throw { code: errorCodes.UPLOAD_PHOTOS_LIMIT_ERROR }
            } else {
              continue
            }
          }
          // 実際にアップロード
          const url = response.payload.url
          const response2 = await uploadFileApi(url, file)
          if (!response2.ok) continue
          // uploadingフラグがtrueのうちはポーリングを繰り返す
          // ただし、ウィンドウを閉じる等の理由でfalseになった場合
          // ポーリングから抜ける
          while (true) {
            await sleep(1500)
            const response3 = await getUploadStatusApi({
              bookId: this.bookId,
              filename: file.name
            })
            if (!response3.ok) break
            if (!this.uploading) break
            if (response3.payload.status === 'fail') break
            if (response3.payload.status === 'success') break
          }
          // 外部からアップロード中フラグをオフにされたら中断
          if (!this.uploading) break
        }
      } catch (e) {
        this.setError(e)
      } finally {
        this.uploading = false
        this.decrementLoadings()
      }
      this.init()
    },
    // アップロード写真を削除
    async removeUploadPhoto (photo) {
      try {
        const response = await removeUploadPhotoApi(photo.id)
        if (!response.ok) {
          throw { code: errorCodes.REMOVE_UPLOAD_PHOTO_ERROR }
        }
        this.init()
      } catch (e) {
        this.setError(e)
      }
    }
  },

  watch: {
    type () {
      this.init()
    }
  }
}
</script>

<style lang="sass" scoped>
.photo-selector
  .tabs
    li
      .selected
        background: white
        font-weight: bold
  .photos
    min-height: 50vh
    > li
      .photo
        overflow: hidden
        padding-top: 100%
        > div
          transition: all .5s ease
          &:hover
            transform: scale(1.5)
</style>
