// ファイルのアップロード

export default async (url, file) => {
  try {
    const response = await fetch(url, {
      method: 'put',
      credentials: 'same-origin',
      body: file
    })
    return response
  } catch (e) {
    return {
      ok: false
    }
  }
}
