// 対象のファイルの削除

import api from '../api'

const path = '/uploads/{id}'

export default resouceId => {
  return api({
    url: path.replace('{id}', resouceId),
    auth: true,
    method: 'delete'
  })
}
